@tailwind components;
@tailwind base;
@tailwind utilities;
@tailwind variants;
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

body {
  width: 100%;
  height: fit-content;
}
