.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}
div.input_container{
  width: 80%;
  height: 50px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid hsl(263, 40%, 59%);
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2em;
  overflow: hidden;
  input{
    background-color: transparent;
    width: 75%;
    height: 100%;
  }
  input:focus{
    outline: none;
  }
  .arrow_container{
    width: 25%;
    padding: 0;
    background: linear-gradient(135deg, hsl(263, 40%, 59%), hsl(263, 67%, 31%));
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    *{
      object-fit: contain;
    }
  }
}
@media screen  and (max-width:864px){
  .App {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
  div.input_container{
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background-color: transparent;
    border: 1px solid hsl(263, 40%, 59%);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2em;
    overflow: hidden;
    input{
      background-color: transparent;
      width: 75%;
      height: 100%;
    }
    input:focus{
      outline: none;
    }
    .arrow_container{
      width: 25%;
      padding: 0;
      background: linear-gradient(135deg, hsl(263, 40%, 59%), hsl(263, 67%, 31%));
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      cursor: pointer;
      *{
        object-fit: contain;
      }
    }
  }
}
